
import { setUser } from '@/store';
import { UserState } from '@/ts/state/UserState';
import { defineComponent } from '@vue/runtime-core';

export default defineComponent({
    emits: ['proceed'],
    computed: {
        study(){
            return this.$store.state.study!;
        },
        user(){
            return this.$store.state.user;
        }
    },
    methods: {
        setSex({ target }: { target: HTMLSelectElement }){
            setUser({ sex: target.value as UserState['sex'] });
        }
    }
});
