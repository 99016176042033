<template>
    <section id='demographics'>
        <div class='parent'>
            <h1>כמה פרטים לפני שנתחיל</h1>
            <div>
                <label>
                    גיל
                    <input type='number' v-model='study.dem.age'/>
                </label>
                <label>
                    מין
                    <select :value='user.sex' @change='setSex($event)'>
                        <option hidden value=null >--------</option>
                        <option value='male'>זכר</option>
                        <option value='female'>נקבה</option>
                        <option value='other'>אחר</option>
                    </select>
                </label>
                <slot></slot>
            </div>
            <button study-button @click='$emit("proceed")' type='button'>
                <span v-if='study.dem.button !== "loading"'>המשך</span>
                <span v-else><img src='/images/loading.gif' /></span>
            </button>
        </div>
    </section>
</template>

<script lang="ts">
import { setUser } from '@/store';
import { UserState } from '@/ts/state/UserState';
import { defineComponent } from '@vue/runtime-core';

export default defineComponent({
    emits: ['proceed'],
    computed: {
        study(){
            return this.$store.state.study!;
        },
        user(){
            return this.$store.state.user;
        }
    },
    methods: {
        setSex({ target }: { target: HTMLSelectElement }){
            setUser({ sex: target.value as UserState['sex'] });
        }
    }
});
</script>

<style lang="scss">
#demographics{
    min-width: 24rem;
    margin: 3rem auto;

    & .parent > div {
        padding: 0 2rem;

        & > label:first-child {
            margin-top: 1rem;
        }
    }
    & button{
        max-height: 3rem;
        background-color: $swatchC;
        margin-top: .5rem;

        &:hover {
            background-color: $hoverC;
        }

        img {
            max-width: 3rem;
            position: relative;
            top: -1rem;
        }
    }

    & .error {
        border: none !important;
        border-radius: $corner;
        background-color: $hoverB;
        display: block;
        width: fit-content;
        margin: 1rem auto 0;
        padding: 1rem 2rem;
        text-align: center;
    }

    & label, & select {
        cursor: pointer;
    }

    & select, & input:not([type = "radio"]) {
        width: 100%;
        margin-top: .2rem
    }

    & label[multi-choice] {
        margin: .3rem .5rem 0 0;
    }

    & fieldset, label:not([multi-choice]) {
        margin: 1.8rem 0;
        width: 100%;
    }
}
</style>